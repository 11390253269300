import React from 'react';

const Cell = ({cellProps}) => {
    return <td className={cellProps.hasError ? "error" : ''}>{cellProps.value}</td>
};

export default function Table({
    data,
}) {

    return (
        <table className="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>FullName</th>
                    <th>Phone</th>
                    <th>Age</th>
                    <th>Email</th>
                    <th>Experience</th>
                    <th>ExpirationDate</th>
                    <th>Has children</th>
                    <th>License Number</th>
                    <th>License states</th>
                    <th>yearly income</th>
                    <th>Duplicate with</th>
                </tr>
            </thead>

            <tbody>
                {
                    data.map(row => {
                        return (
                            <tr key={row.id}>
                                <Cell cellProps={{value : row['id']}} />
                                <Cell cellProps={{value : row['name']}} />
                                <Cell cellProps={row['phone']} />
                                <Cell cellProps={row['age']} />
                                <Cell cellProps={row['email']}/>
                                <Cell cellProps={row['experience']}/>
                                <Cell cellProps={row['expirationDate']} />
                                <Cell cellProps={row['hasChildren']} />
                                <Cell cellProps={row['licenseNumber']} />
                                <Cell cellProps={row['licenseStates']} />
                                <Cell cellProps={row['yearlyIncome']} />
                                <Cell cellProps={row['duplicateWith']} />
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}