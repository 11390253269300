import React from 'react';
import './styles/index.scss';
import Main from "./components/main/main";


function App() {
  return (
      <Main/>
  );
}

export default App;
