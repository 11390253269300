import moment from 'moment'
export const validator = (val) => {
    return {
        valid: true,
        getResult: function() {
            return this.valid
        },
        isNumber: function () {
            if(!this.valid) {
                return this;
            }
            this.valid = isFinite(val) && ( typeof parseInt(val, 10) === 'number');
            return this;
        },
        isString: function(minLength) {
            if(!this.valid) {
                return this;
            }
            if(minLength) {
                this.valid = typeof val === 'string' && val.length >= minLength;
                return this
            }
            this.valid = typeof val === 'string';
            return this
        },
        toStringEqual: function(patters = []) {
            if(!this.valid) {
                return this;
            }
            this.valid = patters.some(str => str === val);
            return this
        },
        moreOrEqualThen: function(v) {
            if(!this.valid) {
                return this;
            }
            const value = parseFloat(val);
            const moreThenValue = parseFloat(v);
            this.valid = (!isNaN(value) && !isNaN(moreThenValue) ) && value >= moreThenValue;
            return this
        },
        lessOrEqualThen: function (v) {
            if(!this.valid) {
                return this;
            }
            const value = parseFloat(val);
            const moreThenValue = parseFloat(v);
            this.valid = (!isNaN(value) && !isNaN(moreThenValue) ) && value <= moreThenValue;
            return this
        },
        matchToFormats: function (formats = []) {
            if(!this.valid) {
                return this;
            }
            this.valid = formats.some(format => moment(val.trim(), format, true).isValid());
            return this;
        }
    }
};