import React from 'react';

const getCsvText = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);

        reader.onload = () => {
            resolve(reader.result)
        };
        reader.onerror = () => {
            reject(reader.error)
        }
    })


};

export default function UploadButton ({
    onUpload,
    children,
    accept

}) {

    const upload = async (e) => {
        const {files} = e.target;

        if(files.length === 0) {
            return false
        }

        const text = await getCsvText(files[0]);

        onUpload(text)
    };

    const btnClickHandler = () => {
        const file = document.querySelector('.upload-file');
        file.click();
    };

    return (
        <div className="upload-btn">
            <input type="file" className="upload-file" accept={accept} onChange={upload}/>
            <div className="btn" onClick={btnClickHandler}>
                {children}
            </div>
        </div>
    )
}