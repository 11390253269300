import React, {useMemo, useState} from 'react';
import TableInvalid from "../../UI/table/invalid";
import UploadButton from "../../UI/uploadButton/uploadButton";
import RenderIf from "../../UI/renderIf/renderIf";
import Table from "../../UI/table/table";
import {validator} from "../../utils/validator";
import moment from 'moment'
import {DATE_FORMAT, ENG_DATE_FORMAT} from '../../utils/consts'

function sertFormat(str) {
    const s = str.startsWith('"') ? str.slice(1, -1) : str;
    const multi = s.split('|');
    const result = multi.map(item => item.split(','));

    console.log({str});


    return result.map((sert) => {
        const sorted = sert
            .splice(0)
            .sort((a,b) => {
                return a.length > b.length ? 1 : -1
            });

        return sorted[0]

    }).join(',')
}


export default function Main() {
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const accept = useMemo(() => '.csv', []);

    const uploadHandler = (file) => {
        const csvRows = file.split(/\r?\n|\r/).filter(i => i);
        const phonesMap = {};
        const emailMap = {};
        const today = moment();
        const rows = csvRows.map(( csvRow , index) => {

            const [
                name,
                phone,
                email,
                age,
                experience,
                yearlyIncome,
                hasChildren,
                licenseStates,
                expirationDate,
                licenseNumber
            ] = csvRow.split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/);
            const id = index + 1;
            const phoneValue = phone.padStart(12, "+1");

            if(error) {
                return {};
            }

            if(!name || !phone || !email) {
                setError(true)
            }

            const result = {
                id,
                name,
                phone : {
                    value : phoneValue,
                    hasError : !!(phonesMap[phoneValue.toLocaleLowerCase()])
                },
                email : {
                    value : email,
                    hasError: !!(emailMap[email.toLocaleLowerCase()])
                },
                age : {
                    value : age,
                    hasError : !(validator(age).isNumber().moreOrEqualThen(21).getResult())
                },
                experience : {
                    value : experience,
                    hasError : !(validator(experience).moreOrEqualThen(0).lessOrEqualThen(age).getResult())
                },
                yearlyIncome : {
                    value : parseFloat(yearlyIncome).toFixed(2),
                    hasError : !(validator(yearlyIncome)
                                        .moreOrEqualThen(0)
                                        .lessOrEqualThen(1e6)
                                        .getResult())
                },
                hasChildren : {
                    value : hasChildren,
                    hasError : !(validator(hasChildren)
                                    .isString()
                                    .toStringEqual(['TRUE', 'FALSE', ''])
                                    .getResult())
                },
                licenseStates: {
                    value : sertFormat(licenseStates),
                },
                expirationDate: {
                    value : expirationDate,
                    hasError: !( (validator(expirationDate)
                                    .matchToFormats([DATE_FORMAT, ENG_DATE_FORMAT])
                                    .getResult()) && today.isAfter(expirationDate))

                },
                licenseNumber : {
                    value : licenseNumber,
                    hasError : !(validator(licenseNumber).isString(6).getResult())
                },
                duplicateWith : {
                    value : phonesMap[phoneValue.toLocaleLowerCase()] || emailMap[email.toLocaleLowerCase()] || "",
                },
            };

            phonesMap[phoneValue.toLocaleLowerCase()] = id;
            emailMap[email.toLocaleLowerCase()] = id;


            return result;
        });
        setRows(rows)
    };

    return (
        <div className="main">
            <div className="main__table">

                <UploadButton accept={accept} onUpload={uploadHandler}>Import users</UploadButton>

                <RenderIf condition={error}>
                    <TableInvalid/>
                </RenderIf>

                <RenderIf condition={!error && rows.length > 0}>
                    <Table data={rows} />
                </RenderIf>


            </div>

        </div>
    )
}